<template>
  <header class="header position-relative">
    <nav class="navbar fixed-top navbar-expand-lg bg-white">
        <div class="container">
           <router-link class="navbar-brand d-flex" :to="{name : 'Page_69_'+userLanguage}">
              <img src="../assets/bg/graphisoft_itech_logo.svg" alt="Graphisoft C++ ITech Challenge">
            </router-link>

            <div class="navbar-collapse justify-content-between collapse" id="headerNavbar">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{headerContents.item01}}</a>
                        <div class="dropdown-menu dropdown-menu-center">
                            <div class="bubble">
                              <router-link class="dropdown-item" :to="{name : 'Page_70_'+userLanguage}">
                                {{headerContents.item01_01}}
                              </router-link>
<!--                              <router-link class="dropdown-item" :to="{name : 'Page_69_'+userLanguage, hash:'#timing'}">-->
<!--                                {{headerContents.item01_02}}-->
<!--                              </router-link>-->
                              <router-link class="dropdown-item" :to="{name : 'Page_69_'+userLanguage, hash:'#awards'}">
                                {{headerContents.item01_03}}
                              </router-link>
                              <router-link class="dropdown-item" :to="{name : 'Page_103_'+userLanguage}">
                                {{headerContents.item01_04}}
                              </router-link>
                            </div> 
                        </div>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{name : 'Page_74_'+userLanguage}">
                        {{headerContents.item05}}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{name : 'Page_76_'+userLanguage}">
                        {{headerContents.item02}}
                      </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{headerContents.item03}}</a>
                        <div class="dropdown-menu dropdown-menu-center">
                            <div class="bubble">
                              <router-link class="dropdown-item" :to="{name : 'Page_77_'+userLanguage}">
                                {{headerContents.item03_01}}
                              </router-link>
                              <router-link class="dropdown-item" :to="{name : 'Page_78_'+userLanguage}">
                                {{headerContents.item03_02}}
                              </router-link>
                              <router-link class="dropdown-item" :to="{name : 'Page_79_'+userLanguage}">
                                {{headerContents.item03_03}}
                              </router-link>
<!--                              <router-link class="dropdown-item" :to="{name : 'Page_80_'+userLanguage}">-->
<!--                                {{headerContents.item03_04}}-->
<!--                              </router-link>-->
                              <router-link class="dropdown-item" :to="{name : 'Page_81_'+userLanguage}">
                                {{headerContents.item03_05}}
                              </router-link>
                              <router-link class="dropdown-item" :to="{name : 'Page_83_'+userLanguage}">
                                {{headerContents.item03_07}}
                              </router-link>
                              <router-link class="dropdown-item" :to="{name : 'Page_287_'+userLanguage}">
                                {{headerContents.item03_08}}
                              </router-link>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" :to="{name : 'Page_75_'+userLanguage}">
                        {{headerContents.item04}}
                      </router-link>
                    </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{headerContents.item06}}</a>
                    <div class="dropdown-menu dropdown-menu-center">
                      <div class="bubble">
                        <router-link class="dropdown-item" :to="{name : 'Page_288_'+userLanguage}">
                          {{headerContents.item06_06}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name : 'Page_259_'+userLanguage}">
                          {{headerContents.item06_05}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name : 'Page_258_'+userLanguage}">
                          {{headerContents.item06_04}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name : 'Page_257_'+userLanguage}">
                          {{headerContents.item06_03}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name : 'Page_256_'+userLanguage}">
                          {{headerContents.item06_02}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name : 'Page_255_'+userLanguage}">
                          {{headerContents.item06_01}}
                        </router-link>
                      </div>
                    </div>
                  </li>
                    <li class="nav-item">
                      <a class="nav-link social" href="https://www.facebook.com/itech.challenge" target="_blank" rel="noreferrer">
                        <i class="bi bi-facebook"></i>
                      </a>
                    </li>
<!--                    <li class="nav-item dropdown login-dropdown">-->
<!--                        <a class="nav-link dropdown-toggle btn btn-primary login-button" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-haspopup="true" aria-expanded="false">Belépés</a>-->
<!--                        <div class="dropdown-menu dropdown-menu-end">-->
<!--                            <div class="bubble">-->
<!--                                <form method="POST" name="loginForm" id="loginForm" @submit.prevent="loginHandler">-->
<!--                                    <div class="form-group">-->
<!--                                        <input name="email" v-model="email" class="form-control" :class="{ 'is-invalid': emailError }"  type="text" :placeholder="loginContent.inputEmail.placeholder" autofocus/>-->
<!--                                        <div v-if="emailError" class="invalid-feedback">-->
<!--                                          {{ emailError }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="form-group">-->
<!--                                        <input name="password"  v-model="password" class="form-control" :class="{ 'is-invalid': passwordError }" type="password" :placeholder="loginContent.inputPassword.placeholder"/>-->
<!--                                        <div v-if="passwordError" class="invalid-feedback">-->
<!--                                          {{ passwordError }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <p class="small mt-3 text-center"><a :href="loginContent.forgotPassword.href" target="_blank" rel="noreferrer">{{loginContent.forgotPassword.text}}</a></p>-->
<!--                                    <div class="form-group">-->
<!--                                        <button class="btn btn-primary btn-block mb-2" type="submit">{{loginContent.submitText}}</button>-->
<!--                                    </div>-->
<!--                                    <p class="small mt-3 text-center"><a :href="loginContent.notRegistered.href" target="_blank" rel="noreferrer" class="register">{{loginContent.notRegistered.text}}</a></p>-->
<!--                                    <div class="clear"></div>-->
<!--                                </form>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
                  <li class="nav-item">
                    <a class="nav-link btn btn-primary" href="https://competitions.ecosim.hu/graphisoft-itech-challenge-hackathon-2024" target="_blank" rel="noreferrer">
                      {{headerContents.item07}}
                    </a>
                  </li>
                  <li class="nav-item" id="languageSelectMenu">
                    <div id="langDrop" class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle text-uppercase" href="#" id="navbarLang" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{userLanguage}}</a>
                      <div class="dropdown-menu dropdown-menu-end text-end pe-2" aria-labelledby="navbarLang">
                        <a class="dropdown-item" @click="changeLanguage('hu')">HU</a>
                        <a class="dropdown-item" @click="changeLanguage('en')">EN</a>
                      </div>
                    </div>
                  </li>
                </ul>
            </div>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#headerNavbar" aria-controls="headerNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>
</header>
</template>

<script>
import {Response} from '@/classes/response.js'
import {Validate} from '@/classes/validate.js'

export default{
  name:'Header',
  props:['globalData'],
  data(){

    return{
      password:'',
      passwordError:null,
      email:'',
      emailError:null
    }
  },
  computed: {
    userLanguage() {
      return this.globalData.init.language
    },
    contents() {
      let language = this.userLanguage
      return this.globalData.contents[language][69]
    },
    headerContents(){
      return this.contents.header
    },
    loginContent(){
      return this.contents.loginModal
    }
  },
  methods:{
    changeLanguage(language){
      this.globalData.init.language = language

      let formData = new FormData()
      formData.append('language', language)

      this.globalData.loader.visible = true;
      this.$axios.post('v2/user/language',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})
            if(responseData.status===true) {
              this.globalData.init.language = language
            }

          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.globalData.loader.visible = false;
          });
    },
    loginHandler(){
        let validate = new Validate()

        this.emailError = validate.email(this.email, 'Valós e-mail címet kell megadni!')
        this.passwordError = validate.password(this.password, 'A jelszó minimum 6 karakteres!')

        if (this.emailError || this.passwordError) {
          return false;
        }

        let formData = new FormData()
        formData.append('email', this.email)
        formData.append('password', this.password)

        this.globalData.loader.status = true
        this.$axios.post('v2/user/auth', formData)
          .then((response)=>{
            let responseData=new Response().handle(response);
            this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true){

            }
          })
          .catch(error =>{
            console.log(error)
             let responseData =  new Response()
              responseData.errorHandle(error.response)
              this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });
    }
  }
}
</script>

<style scoped>
header{box-shadow:rgba(0, 0, 0, 0.1) 0 5px 5px;}
.navbar-brand{margin:0;}
.navbar-brand img{height: 66px}
.nav-link{
  line-height:0.875rem;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 8px;
}
.nav-link.social{
  font-size: 2rem;
  padding-top: 0;
  padding-bottom: 0;
}
.nav-link:focus, .nav-link:hover, .nav-item.active .nav-link {
    color: #313d6b;
    font-weight: bold;
}
.nav-link.login-button{padding:.5rem 2.5rem!important;margin-left: .5rem; margin-top: -7px;text-decoration: none!important;}
.nav-link.login-button.dropdown-toggle::after{margin-bottom: -2px;}
.navbar-toggler{position:absolute;right:0;top:30px;}
.navbar-toggler-icon{background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");}
.nav-item:focus .nav-link,.nav-item:hover .nav-link,.nav-item.active .nav-link{
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}
.bubble {
    position: relative;
    padding:.5rem 1.5rem;
}
.bubble::before{
    content: '';
    position: absolute;
    top: 10px;
    right: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #FFFFFF;
    border-top: 0;
    margin-left: -26px;
    margin-top: -26px;
}
.dropdown-menu{
    border-radius: 0;
    border: none;
    margin-top: 3rem;
    padding: 0.5rem 0;
}
.dropdown-menu-center{
    left: 50% !important;
    right: auto !important;
    transform: translate(-50%, 0) !important;
}
.dropdown-menu-end{
    right: 0;
}
.dropdown-menu-end .bubble::before{
    left: 80%;
}
.dropdown-item{
    margin-bottom: .75rem;
    padding: 0;
    line-height: 1rem;
    cursor: pointer;
}
.dropdown-item:focus{
    color: #ff6104;
    background-color: #FFFFFF;
    font-weight: 700;
}
.form-control{
    margin-bottom: .75rem;
    border-radius: 0;
}
.nav-link.login-button{
  font-size: 16px;
  line-height: 1.5;
  text-transform: none;
}
#loginForm {
    text-align: center;
}
#loginForm .register {
    text-transform: uppercase;
    text-decoration: none;
}
#loginForm a {
    color: #ff6104;
    font-weight: 600;
    white-space: nowrap;
}
.invalid-feedback{
  display: block;
  color: #8b0000;
  margin-top: -8px;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
    /*  HEADER  */
    #header .left{float:none;}
    #header .right{float:none;}
    #header .logo{margin:10px 0;}
    #header   .btn-circle-blue{margin-top:0;}
    .navbar-nav{
        align-items: center;
    }
    .nav-item{
        margin-bottom: .25rem;
    }
    .nav-link, .bubble{
        text-align: center;
    }
    .dropdown-menu{
        margin-top: 0;
    }
    .dropdown-menu-center{
        left: initial!important; 
        right: auto!important;
        transform: none!important;
    }
    .bubble::before{display: none;}
    .login-button{margin-top:0;}
}
</style>